import { capitalize } from "@mui/material";
import { Statusable } from "models/BoUser";
import { BaseContent } from "./Content";

export enum CommType {
  Email = 1,
  SMS = 2,
  Push = 3,
}

export class Comm extends BaseContent {
  id: string;
  title: string;
  body: string;
  email:any;
  event:string;
  param: string;
  type: CommType;
}
