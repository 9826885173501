import { GridColDef } from "@mui/x-data-grid";
import { AddGeneralCodeDialog } from "components/alove/AddGeneralCodeDialog";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { TwoSizeChip } from "components/alove/Chips";
import { TablePage } from "components/alove/TablePage";
import { setCurrentDialog, useMaterialUIController } from "context";
import { PermissionType } from "models/BoRole";
import { Comm, CommType } from "models/Comm";
import { useNavigate } from "react-router-dom";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { getComms, publishComms } from "utils/commUtils";
const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const CommsList = () => {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      headerName: "Title",
      field: "title",
      flex: 1,
    },
    {
      headerName: "Body",
      field: "body",
      flex: 1,
    },
    {
      headerName: "Event",
      field: "event",
      flex: 1,
      valueGetter: (v) => camelCaseToCapitalizedWords(v),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => {
        const comm: Comm = Object.assign(new Comm(), params.row);
        return (
          comm.statusId && <TwoSizeChip label={comm.statusLabel} color={comm.statusColor as any} />
        );
      },
    },
  ];

  const loadComms = (type:CommType) => () => getComms(type);

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Comms" />
      <TablePage
        actions={{
          title: "Comms actions",
          saveActions: [
            {
              label: "Publish",
              variant: "contained",
              role: PermissionType.Publish,
              onClick: () => publishComms(),
            },
          ],
          addActions: [
            {
              label: "Comm",
              onClick: () => navigate("/comms/new"),
              variant: "contained",
              color: "secondary",
            },
            {
              label: "Category",
              onClick: () => setCurrentDialog(dispatch, DialogType.AddGeneralCode),
              variant: "contained",
              color: "secondary",
            },
          ],
        }}
        table={{
          loadNext: loadComms(CommType.Email),
          columns,
          minHeight: 50,
          lineClick: (comm) => !isNaN(comm.id) && navigate(`/communications/${comm.id}`),
        }}
        title="Emails"
      />{" "}
      <TablePage
        table={{
          loadNext: loadComms(CommType.SMS),
          columns,
          minHeight: 50,
          lineClick: (comm) => !isNaN(comm.id) && navigate(`/communications/${comm.id}`),
        }}
        title="SMS"
      />
      <TablePage
        table={{
          loadNext: loadComms(CommType.Push),
          columns,
          minHeight: 50,
          lineClick: (comm) => !isNaN(comm.id) && navigate(`/communications/${comm.id}`),
        }}
        title="Push"
      />
      <AddGeneralCodeDialog />
    </DashboardLayout>
  );
};
