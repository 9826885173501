import { useEffect, useState } from "react";
import {
  GridActionsCellItem,
  DataGridPro,
  GridColDef,
  GridEventListener,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ScreenModel, MenuScreenItem } from "models/Screen";
import { ActionSetting } from "./action";
import { getScreens } from "utils/screenUtils";
import { GridColDefPro } from "@mui/x-data-grid-pro/typeOverloads";

const MDButton = require("components/MDButton/index.js").default;

export const MenuForm = ({
  screen,
  setScreen,
}: {
  screen: ScreenModel;
  setScreen: (screen) => void;
}) => {
  const [screens, setScreens] = useState<ScreenModel[]>([]);

  useEffect(() => {
    getScreens().then((screens) => setScreens(screens));
  }, []);

  const handleEditCellChange = (
    newRow: MenuScreenItem,
    oldRow: MenuScreenItem,
    params?: {
      rowId: GridRowId;
    }
  ): any => {
    const updatedItems = [...screen.menuItems];
    updatedItems[params?.rowId ?? 0] = newRow;
    setScreen({ ...screen, menuItems: updatedItems });
    return newRow;
  };

  const handleDeleteRow = (id) => {
    setScreen({
      ...screen,
      menuItems: screen.menuItems.filter((_, index) => index !== id),
    });
  };

  const handleAddRow = () => {
    setScreen({
      ...screen,
      menuItems: [...screen.menuItems, { title: "", subtitle: "", panel: "" }],
    });
  };

  const columns: GridColDef<MenuScreenItem>[] = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      editable: true,
    },
    {
      field: "subtitle",
      headerName: "Subtitle",
      flex: 2,
      editable: true,
    },
    {
      field: "panel",
      headerName: "Panel",
      flex: 1,
      editable: true,
    }
  ];

  const getDetailPanelContent = (params) => (
    <Grid container mt={1}>

<Grid item xs={1}/>
      <Grid item xs={9}>
      <ActionSetting
        action={{
          nextEventType: params.row.nextEventType,
          actionUrl: params.row.actionUrl,
        }}
        setAction={(action) => {
          const updatedItems = [...screen.menuItems];
          updatedItems[params.id].nextEventType = action.nextEventType;
          updatedItems[params.id].actionUrl = action.actionUrl;
          setScreen({ ...screen, menuItems: updatedItems });
        }}
        screens={screens}
      />
      </Grid>
      <Grid xs={true}/>
      <Grid item xs={1}>
       <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteRow(params.id)}
          color="inherit"
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid style={{ width: "100%" }} container gap={1} flexDirection="column" justifyContent={"center"}>
      <DataGridPro
        rows={screen.menuItems.map((item, index) => ({ id: index, ...item }))}
        columns={columns}
        editMode="cell"
        processRowUpdate={handleEditCellChange}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 70}
      />
      <MDButton variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddRow}>
        Add Tile
      </MDButton>
    </Grid>
  );
};
