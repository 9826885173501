import { Autocomplete, Dialog, Grid } from "@mui/material";
import { useMaterialUIController } from "context";
import { AssetStatus, ProfileAsset } from "models/Profile";
import React, { useEffect } from "react";
import { getImages, updateImageStatus } from "utils/enduserUtils";
import { avatarUrl, camelCaseToCapitalizedWords } from "utils/globalsUtils";

const MDInput = require("components/MDInput/index.js").default;

export const PersonalImages = ({ profileId }: { profileId: string }) => {
  const [controller] = useMaterialUIController();
  const { currentHost } = controller;
  const [showImageDialog, setShowImageDialog] = React.useState("");
  const [images, setImages] = React.useState([] as ProfileAsset[]);
  useEffect(() => {
    if (!profileId) return;
    getImages(profileId).then((res) => {
      setImages(res);
    });
  }, [profileId]);


  return (
    <Grid container gap={2} wrap="nowrap" style={{  overflowX: "scroll" }}>
      {images
      .map((img) => {
        const options = Object.keys(AssetStatus)
        .filter((key) => isNaN(Number(key)))
        .map((key) => ({
            title: key,
            id: AssetStatus[key],
            }));
        return (
          <Grid item key={img?.uri} container direction="column"  gap={2} style={{ width: "200px"}}>
            <img
              src={avatarUrl(profileId, img?.uri)}
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
              onClick={() => setShowImageDialog(img?.uri)}
            />
            <Autocomplete
              options={options}
              size="small"
              disableClearable
              getOptionLabel={(option) => camelCaseToCapitalizedWords(option.title)}
              value={options.find((o) => o.id == img.assetStatusId) || options[0]}
              onChange={(e, value) => {
                img.assetStatusId = value?.id;
                setImages([...images]);
                updateImageStatus(profileId, img.uri, value?.id);
              }}
              renderInput={(params) => <MDInput {...params} label="Status" />}
            />
          </Grid>
        );
      })}
      <Dialog
        open={!!showImageDialog}
        onClose={() => setShowImageDialog("")}
        fullWidth
        maxWidth="md"
      >
        <img
          src={avatarUrl(profileId, images.find((i) => i.uri == showImageDialog)?.uri)}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Dialog>

    </Grid>
  );
};
