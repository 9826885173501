import { Autocomplete, Card, Divider, Grid } from "@mui/material";
import { GeneralCode, GeneralCodeType } from "models/GeneralCode";
import { appPages, NextScreenEvent, ScreenEvent, ScreenModel, ScreenType } from "models/Screen";
import { useEffect, useState } from "react";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { getScreens } from "utils/screenUtils";
import { ActionSetting } from "./action";
const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const ScreenWorkflow = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  const [screens, setScreens] = useState([] as ScreenModel[]);
  useEffect(() => {
    getScreens().then((screens) => setScreens(screens));
  }, []);

  return (
    <Grid container flexDirection="row" justifyContent="start" alignItems="center" gap={2}>
      <Grid item xs={2}>
        <MDTypography variant="body2">Trigger Event</MDTypography>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          value={screen.event || ScreenEvent.afterApprove}
          fullWidth
          disableClearable
          getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
          onChange={(e, o) => setScreen({ ...screen, event: o })}
          options={Object.values(ScreenEvent).filter((key) => isNaN(Number(key)))}
          renderInput={(params) => <MDInput {...params} label="Trigger Event" />}
        />
      </Grid>
      <Grid item xs={3}>
        <MDInput
          fullWidth
          label="Param"
          value={screen.param || ""}
          onChange={(e: any) => setScreen({ ...screen, param: e.target.value })}
        />
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid item xs={2}>
        <MDTypography variant="body2">Next Event</MDTypography>
      </Grid>
      <Grid item xs={8}>
      <ActionSetting
        action={{
          nextEventType: screen.nextEventType,
          actionUrl: screen.actionUrl,
        }}
        setAction={(action) =>
          setScreen({ ...screen, nextEventType: action.nextEventType, actionUrl: action.actionUrl })
        }
        screens={screens}
      />
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid item xs={2}>
        <MDTypography variant="body2">Next Event 2</MDTypography>
      </Grid>
      <Grid item xs={8}>
      <ActionSetting
        action={{
          nextEventType: screen.nextEventType2,
          actionUrl: screen.actionUrl2,
        }}
        setAction={(action) =>
          setScreen({
            ...screen,
            nextEventType2: action.nextEventType,
            actionUrl2: action.actionUrl,
          })
        }
        screens={screens}
      />
      </Grid>
    </Grid>
  );
};
