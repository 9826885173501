import { Card, Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import ItemPageContainer from "layouts/itemPageContainer";
import { Comm } from "models/Comm";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createCommDO, deleteCommDO, getComm, saveCommDO } from "utils/commUtils";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BoRole";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const CommsView = () => {
  const pageNumber = "3.6";
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const [comm, setComm] = useState({
  } as Comm);
  const { id } = useParams();

  useEffect(() => {
    if (id && id !== "new") {
      getComm(id).then((comm) => setCommObject(comm));
    }
  }, [id]);

  const setCommObject = (newComm) => {
    setComm(Object.assign(new Comm(), newComm));
  };

  const saveComm = async () =>
    id === "new" ? createCommDO(comm).then((comm) => navigate(-1)) : saveCommDO(comm);

  const deleteComm = async () => deleteCommDO(comm).then(() => navigate(-1));

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Comms" />
      <ItemPageContainer
        navItems={[
          { label: "Comm settings", ref: "settings" },
          { label: "Edit comm", ref: "comm" },
          { label: "Workflow", ref: "workflow" },
        ]}
        actions={{
          actionsTitle: "Comm actions",
          itemName: `Information comm`,
          itemNumber: `#${comm.id}`,
          actions: [
            {
              title: "Save",
              onClick: saveComm,
              variant: "contained",
            },
          ],
        }}
        itemStatus={{
          title: "Comm status",
          item: comm,
          isManager: true,
          changeStatus: async (newStatus) => {
            comm.statusId = newStatus;
            setCommObject({ ...comm });
            await saveComm();
          },
        }}
        itemHeader={<SMEHeader />}
        deleteItem={{
          buttonText: "Delete Comm",
          contetnText: "Are you sure you want to delete this comm?",
          hidden: !currentRole.hasAccess(pageNumber, PermissionType.Delete),
          onDelete: deleteComm,
        }}
      >
        <CardItem title="Edit comm" id="comm">
          Comm template
        </CardItem>
      </ItemPageContainer>
    </DashboardLayout>
  );
};
