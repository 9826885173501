import axios from "axios";
import { getBrandsDO } from "./systemUsersUtils";
import { capitalize } from "@mui/material";
import { getBucket, networkGlobals } from "./networkUtils";

const serverHost = "https://api.a-dmin.ai";
let trasnlations = {};

export const getTranslationsByBranDO = async () => {
  const brands = await getBrandsDO(networkGlobals.host);
  const host = networkGlobals.host;

  for (const brand of brands) {
    const translitionsByBrand = await getTranslitions(brand?.folder, host);
    trasnlations[brand?.name] = translitionsByBrand;
  }
  return trasnlations;
};

function getTranslitions(brand, host): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(`https://${getBucket()}.s3.amazonaws.com/public/generated/${brand}/translations/en.json`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.error("error from get JSON translation:", error);
        reject(error);
      });
  });
}

export function tr(key: string, args:{[key: string]: string} = {}): string {
  const text =  trasnlations[networkGlobals.brand.name]?.[key] 
  || trasnlations[networkGlobals.brand.name]?.[key?.toLowerCase()]
  || key;
  console.log("text", text);
  return text.replace(/@(\w+)/g, (match, capture) => args[capture] || match);
}

export function toCapitalizedWords(name: string): string {
  const words: string[] = name?.match(/[A-Za-z.][a-z]*/g) || [];
  return words?.map(capitalize).join(" ");
}

export function camelcaseToSnakeCase(str: string, separator: string = "_"): string {
  if (str?.length === 0 || !str?.replace) {
    return "";
  }
  return str.replace(/[A-Z]/g, (letter) => `${separator}${letter.toLowerCase()}`);
}

export function snakeCaseToCamelCase(name: string): string {
  const upper = (match: string) => match.charAt(1).toUpperCase();
  return name.replace(/(-\w)/g, upper).replace(/_\w/g, upper);
}

export function camelCaseToCapitalizedWords(name: string): string {
  return toCapitalizedWords(camelcaseToSnakeCase(name));
}

export function snakeCaseToCapitalizedWords(name: string): string {
  var words = name?.split(/_|-/) || [];

  return words.map((s) => capitalize(s.toLowerCase())).join(" ");
}

export function formatDateTime(dateStr: string | Date | undefined): string {
  const d = new Date(dateStr || "");
  if (d.toString() == "Invalid Date") {
    return "-";
  }
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    d.toLocaleString("default", { month: "short" }) +
    "-" +
    d.getFullYear() +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2)
  );
}

export function formatDate(dateStr: string | Date | undefined): string {
  const d = new Date(dateStr || "");
  if (d.toString() == "Invalid Date") {
    return "-";
  }
  return (
    ("0" + d.getDate()).slice(-2) +
    "-" +
    d.toLocaleString("default", { month: "short" }) +
    "-" +
    d.getFullYear()
  );
}

export const updateAttribute = (key: string, value: any, obj: any) => {
  const keys = key.split(".");
  let current = obj;
  for (let i = 0; i < keys.length; i++) {
    if (i === keys.length - 1) {
      current[keys[i]] = value;
    } else {
      current[keys[i]] ||= {};
      current = current[keys[i]];
    }
  }

  return obj;
};

export const avatarUrl = (profile: string | undefined, asset: string | undefined): string => {
  let bucket = getBucket();
  return `https://${bucket}.s3.amazonaws.com/protected/${profile}/${asset}.jpg`;
};

export const groupBy = function (xs, func) {
  return xs.reduce(function (rv, x) {
    (rv[func(x)] = rv[func(x)] || []).push(x);
    return rv;
  }, {});
};

export const formtHeight = (height: number): string => {
  if (isNaN(height)) {
    return "";
  }
  let feet = Math.floor(height / 12);
  let inches = Math.floor(height % 12);
  return `${feet}'${inches}"`;
};

// export const camelcaseToSankeCaseAttributes = (obj) => {
//   let newObj = {};
//   for (let key in obj) {
//     if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
//       newObj[camelcaseToSnakeCase(key)] = camelcaseToSankeCaseAttributes(obj[key]);
//     } else {
//       newObj[camelcaseToSnakeCase(key)] = obj[key];
//     }
//   }
//   return newObj;
// }

export const snakeCaseToCamelCaseAttributes = (obj) => {
  let newObj = {};
  for (let key in obj) {
    newObj[snakeCaseToCamelCase(key)] = obj[key];
  }
  return newObj;
};

export const getDistanceInMiles = (location1, location2): number => {
  if (!location1?.lat || !location1?.lng || !location2?.lat || !location2?.lng) {
    return 0;
  }
  const distance = Math.sqrt(
    Math.pow(location1.lat - location2.lat, 2) + Math.pow(location1.lng - location2.lng, 2)
  );

  return distance * 69;
};

export const uniqueBy = (arr: any[], key: string): any[] => {
  return [...new Map(arr.filter(i=>i && i[key]).map((item) => [item[key], item])).values()];
};

export async function changeFavicon(folder) {
  const oldLink = document.getElementsByTagName("link").item(0);
  if (oldLink) {
    oldLink.href = `https://alove-dev-assets.s3.amazonaws.com/public/uploaded/${folder.toLowerCase()}/png/cropped.png`;
    console.log(oldLink?.href);
  }
}
