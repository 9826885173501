import { capitalize } from "@mui/material";
import { Statusable } from "./BoUser";

export enum ContentStatus {
  draft = 1,
  active = 2,
  deactive = 3,
}

export class BaseContent implements Statusable {
  statusId: number;

  get status(): string {
    return this.statusId?.toString();
  }

  get statusLabel(): string {
    return capitalize(ContentStatus[this.statusId]);
  }

  get statusColor(): string {
    return this.statusId == ContentStatus.active ? "success" : "error";
  }

  get statuses(): { key: string; value: string }[] {
    return Object.keys(ContentStatus)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({
        key: key,
        value: capitalize(ContentStatus[key]),
      }));
  }

  set status(value: string) {
    this.statusId = Number(value);
  }
}

export class Content {
  languageId: string;
  entityId: string;
  txt: string;
  brandId: number;
  created: string;
  createdBy: number;
  updated: string;
  updatedBy: number;
  position: number[];
  preValidated: string;
  status: number;
  autoGenerated: boolean;
  answer: any;
  question: any;

  get id(): string {
    return this.entityId;
  }
}
