import { Comm, CommType } from "models/Comm"
import { makeRequest } from "./networkUtils"

export const getComms = (type:CommType): Promise<Comm[]> => {
    return makeRequest("comms?type=" + type)
}

export const getComm = (id: string): Promise<Comm> => {
    return makeRequest(`comms/${id}`)
}

export const createCommDO = (comm: Comm): Promise<Comm> => {
    return makeRequest("comms", { method: "POST", body: comm })
}

export const saveCommDO = (comm: Comm): Promise<Comm> => {
    return makeRequest(`comms/${comm.id}`, { method: "PUT", body: comm })
}

export const deleteCommDO = (comm: Comm): Promise<void> => {
    return makeRequest(`comms/${comm.id}`, { method: "DELETE" })
}

export const publishComms = (): Promise<void> => {
    return makeRequest("utils/publish", { method: "POST", body: { type: "comms" } })
}