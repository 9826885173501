import { GridColDef } from "@mui/x-data-grid";
import { AddGeneralCodeDialog } from "components/alove/AddGeneralCodeDialog";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { TwoSizeChip } from "components/alove/Chips";
import { TablePage } from "components/alove/TablePage";
import { setCurrentDialog, useMaterialUIController } from "context";
import { PermissionType } from "models/BoRole";
import { ScreenModel, ScreenType } from "models/Screen";
import { useNavigate } from "react-router-dom";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { getScreens, publishScreens } from "utils/screenUtils";
const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const ScreensList = () => {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { headerName: "Type", field: "type", flex: 1, valueGetter: (v) => camelCaseToCapitalizedWords(ScreenType[v]) },
    { headerName: "Body", field: "body", flex: 4, valueGetter: (v, r) => 
      r.type === ScreenType.CardsScreen ? "Cards" 
      : r.type === ScreenType.MenuScreen ? "Menu"
      : r.type === ScreenType.SliderScreen ? "Slider"
      : v },
    {
      headerName: "Event",
      field: "event",
      flex: 1,
      valueGetter: (v) => camelCaseToCapitalizedWords(v),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => {
        const screen:ScreenModel = Object.assign(new ScreenModel(), params.row);
        return screen.statusId && <TwoSizeChip label={screen.statusLabel} color={screen.statusColor as any} />;
      },
    }
  ];

  const loadScreens = () => getScreens();

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Screens" />
      <TablePage
        actions={{
          title: "Screens actions",
          saveActions: [
            {
              label: "Publish",
              variant: "contained",
              role: PermissionType.Publish,
              onClick: () => publishScreens(),
            },
          ],
          addActions: [
            {
              label: "Screen",
              onClick: () => navigate("/screens/new"),
              variant: "contained",
              color: "secondary",
            },
            {
              label: "Category",
              onClick: () => setCurrentDialog(dispatch, DialogType.AddGeneralCode),
              variant: "contained",
              color: "secondary",
            }
          ],
        }}
        table={{
          loadNext: loadScreens,
          columns,
          getTreeDataPath: (row) => [row.category?.name || "No category", `${row.id} - ${row.name || ""}`],
          lineClick: (screen) => !isNaN(screen.id) && navigate(`/screens/${screen.id}`),
          groupingColDef: { width: 300 },
        }}
        title="Screens"
      />
      <AddGeneralCodeDialog />
    </DashboardLayout>
  );
};
