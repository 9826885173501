import { Statusable } from "./BoUser";

export enum ProfileStatus {
  Active = 1,
  Deactivated = 2,
  Snoozed = 3,
  GDPR = 4,
  Suspended = 5,
  Pending = 6,
  DNFQuestn = 7,
}

// Enabled not verified - Pending verification
// Disabled by user - User disabled in setting screen (Hide photo)
// Enabled verified - Verified automaticallyas main photo
// Failed validation - Image is not valid
// Disabled by operator - Manually disabled in admin screen
// Overridden by user - User replaced the photo in setting screen
// Deleted by user - User deleted the photo in setting screen
// Approved as secondary - Approved as secondary photo but not main, (Multuiple photos, Childs)

export enum AssetStatus {
  enabledNotVerified = 1,
  disabledByUser = 2,
  enabledVerified = 3,
  failedValidation = 4,
  disabledByOperator = 5,
  overridedByUser = 6,
  deletedByUser = 7,
  approvedAsSecondary = 8,
}


export enum UsageEventType {
  picSent = "picSent",
  videoSent = "videoSent",
  audioCall = "audioCall",
  videoCall = "videoCall",
  BTI = "BTI",
  dailyMood = "dailyMood",
}

export class Profile implements Statusable {
  public profileId?: string;
  public attributesPolicy?: any;
  public created?: string;
  public isInternal?: boolean;
  public lastSuccessfulLogin?: string;
  public lastLogout?: string;
  public attributesValues: { [key: string]: any[] };
  public statusId?: number;
  public snoozedUntil?: string;
  public updated?: string;
  public statusChangedAt?: string;
  public snoozeReasonId?: string;
  public userId?: string;
  public mood?: string;
  public statusLine?: string;
  public lastIntroductionRequest?: string;
  public picturesCounter?: number;
  public picturesUpdated?: string;
  public plan?: number;
  public tokens?: string;
  public stripeCustomerId?: string;
  public settings?: any;
  public brandId?: number;
  public parentUserId?: string;
  public purchasedAt?: string;


  get statusLabel(): string {
    return ProfileStatus[this.statusId || 1];
  }

  get statusColor(): string {
    switch (Number(this.statusId)) {
      case ProfileStatus.Active:
        return "success";
      case ProfileStatus.Snoozed:
      case ProfileStatus.Pending:
      case ProfileStatus.DNFQuestn:
        return "warning";
      case ProfileStatus.GDPR:
      case ProfileStatus.Suspended:
      case ProfileStatus.Deactivated:
        return "error";
      default:
        return "info";
    }
  }

  get statuses(): { key: string; value: string }[] {
    return Object.keys(ProfileStatus)
      .filter((key) => !isNaN(Number(key)))

      .map((key) => ({
        key,
        value: ProfileStatus[key],
      }));
  }

  get statusReason(): string {
    return this.attributesValues?.["statusReason"]?.[0] || "";
  }

  get status(): string {
    return this.statusId?.toString() || "";
  }

  get location(): string {
    return (
      this.attributesValues?.livingPlace?.[0]?.city +
      ", " +
      this.attributesValues?.livingPlace?.[0]?.state
    );
  }


  get gender(): string {
    switch (this.attributesValues?.gender?.[0]) {
      case 4:
        return "Man"
      case 5:
        return "Woman"
      default:
        return this.attributesValues?.gender?.[0]
    }
  }
}

export class ProfileAsset {
  uri: string;
  assetStatusId: AssetStatus;
}
