import { Autocomplete, Grid } from "@mui/material";
import { appPages, NextScreenEvent, ScreenModel } from "models/Screen";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";

const MDInput = require("components/MDInput/index.js").default;

export const ActionSetting = ({ action, setAction, screens }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          value={action.nextEventType || ""}
          fullWidth
          getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
          onChange={(e, o) => setAction({ ...action, nextEventType: o })}
          options={Object.values(NextScreenEvent).filter((key) => isNaN(Number(key)))}
          renderInput={(params) => <MDInput {...params} label="Event Type" />}
        />
      </Grid>
      {action.nextEventType === NextScreenEvent.genericScreen ? (
        <Grid item xs={6}>
          <Autocomplete
            value={screens.find((s) => s.id == action.actionUrl) || ({} as ScreenModel)}
            fullWidth
            getOptionLabel={(option: ScreenModel) => option.name}
            onChange={(e, o) => setAction({ ...action, actionUrl: o?.id })}
            options={screens}
            renderInput={(params) => <MDInput {...params} label="Next action" />}
          />
        </Grid>
      ) : action.nextEventType === NextScreenEvent.appScreen ? (
        <Grid item xs={6}>
          <Autocomplete
            value={action.actionUrl || ""}
            fullWidth
            freeSolo
            getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) => setAction({ ...action, actionUrl: o })}
            options={appPages}
            renderInput={(params) => <MDInput {...params} label="Action URL" />}
          />
        </Grid>
      ) : (
        <Grid item xs={6}>
          <MDInput
            fullWidth
            label="Param"
            value={action.actionUrl || ""}
            onChange={(e: any) => setAction({ ...action, actionUrl: e.target.value })}
          />
        </Grid>
      )}
    </Grid>
  );
};
